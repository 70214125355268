// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import "bootstrap";
/** Ionic CSS Variables **/
:root {
  /** Primary **/
  --ion-color-primary: #FF6363;
  --ion-color-primary-rgb: 255, 99, 99;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e05757;
  --ion-color-primary-tint: #ff7373;

  /** Secondary **/
  --ion-color-secondary: #A759E4;
  --ion-color-secondary-rgb: 167, 89, 228;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #934ec9;
  --ion-color-secondary-tint: #b06ae7;

  /** Tertiary **/
  --ion-color-tertiary: #6C59E4;
  --ion-color-tertiary-rgb: 108, 89, 228;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5f4ec9;
  --ion-color-tertiary-tint: #7b6ae7;

  /** Success **/
  --ion-color-success: #3AF2B0;
  --ion-color-success-rgb: 58, 242, 176;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #33d59b;
  --ion-color-success-tint: #4ef3b8;

  /** Warning **/
  --ion-color-warning: #FFC90C;
  --ion-color-warning-rgb: 255, 201, 12;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b10b;
  --ion-color-warning-tint: #ffce24;

  /** Danger **/
  --ion-color-danger: #CA1C28;
  --ion-color-danger-rgb: 255, 5, 5;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e00404;
  --ion-color-danger-tint: #ff1e1e;

  /** Info **/
  --ion-color-info: #3FC5FF;
  --ion-color-info-rgb: 63, 197, 255;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #37ade0;
  --ion-color-info-tint: #52cbff;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #fefefe;
	--ion-color-light-rgb: 254,254,254;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #f9f9f9;

  --color-bg: #FFF !important;
  --color-border: #000 !important;

  --color-trans: transparent !important;
  
  /** typography **/
  // h1 {
  //   font-size: 18pt;
  // }
  
  // h2 {
  //   font-size: 16pt;
  // }
  
  // h3 {
  //   font-size: 14pt;
  // }
  
  // h4 {
  //   font-size: 12pt;
  // }
  
}


.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

// @media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body[color-theme = "dark"] {
    /** Primary **/
    --ion-color-primary: #FF6363;
    --ion-color-primary-rgb: 255, 99, 99;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e05757;
    --ion-color-primary-tint: #ff7373;

    /** Secondary **/
    --ion-color-secondary: #A759E4;
    --ion-color-secondary-rgb: 167, 89, 228;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #934ec9;
    --ion-color-secondary-tint: #b06ae7;

    /** Tertiary **/
    --ion-color-tertiary: #6C59E4;
    --ion-color-tertiary-rgb: 108, 89, 228;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5f4ec9;
    --ion-color-tertiary-tint: #7b6ae7;

    /** Success **/
    --ion-color-success: #3AF2B0;
    --ion-color-success-rgb: 58, 242, 176;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #33d59b;
    --ion-color-success-tint: #4ef3b8;

    /** Warning **/
    --ion-color-warning: #FFC90C;
    --ion-color-warning-rgb: 255, 201, 12;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0b10b;
    --ion-color-warning-tint: #ffce24;

    /** Danger **/
    --ion-color-danger: #CA1C28;
    --ion-color-danger-rgb: 255, 5, 5;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e00404;
    --ion-color-danger-tint: #ff1e1e;

    /** Info **/
    --ion-color-info: #3FC5FF;
    --ion-color-info-rgb: 63, 197, 255;
    --ion-color-info-contrast: #000000;
    --ion-color-info-contrast-rgb: 0, 0, 0;
    --ion-color-info-shade: #37ade0;
    --ion-color-info-tint: #52cbff;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --color-bg: #000 !important;
    --color-border: #FFF !important;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body[color-theme = "dark"] {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    --color-bg: #000 !important;
    --color-border: #FFF !important;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body[color-theme = "dark"] {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #FFF;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;

    --color-bg: #000 !important;
    --color-border: #FFF !important;
  }
// }