/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @font-face {
//   font-family: 'Rubik';
//   src: url('./assets/fonts/Rubik-Regular.ttf');
// }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

:root {
  * {
    font-family: 'Poppins' !important;
    --font-family: 'Poppins' !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  .header-md::after {
    background-image: none !important;
  }

  .header-ios ion-toolbar:last-of-type {
    --border-width: 0 !important;
  }

  ion-header {
    ion-toolbar {
      --background: var(--ion-color-light);
      --color: var(--ion-color-primary);
      padding: 16px;

      ion-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;

        img {
          height: 32px;
          width: 32px;
        }
      }

      ion-title {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }


  .md ion-searchbar {
    padding-top: 20px;
  }

  ion-tab-bar {
    --border: none;
    height: 76px;

    ion-tab-button {
      background: var(--ion-color-light);

      ion-icon {
        font-size: 22px;
      }
    }
  }

  ion-content {
    --background: var(--ion-color-light-tint);
    --font-family: 'Poppins' !important;
  }

  ion-item,
  ion-list {
    --background: transparent !important;
    --background-color: transparent !important;
    background: transparent !important;
  }
}

ion-refresher-content {
  background: #b80815 !important;
  --color:#FFF!important;
}

.transform-none {
  text-transform: none !important;
}

.decorated-text {
  overflow: hidden;
  position: relative;
}

.decorated-text>span {
  display: inline-block;
  // background: #f9f9f9;
  // background: #f9f9f9;
  background: var(--ion-color-light-tint);
  padding: 0 10px 0 0px;
  position: relative;
  left: 0%;
  /* play with this one as you like */
}

.decorated-text::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  border-bottom: 2px solid #807979;
  width: 100%;
}

.title-content {
  font-size: 22px;
  font-weight: bolder;
}

.icon-toolbar {
  font-size: 28px !important;
  color: white;
}

.b-radius20 {
  border-radius: 20px;
}


.c-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 12px 0 12px !important;
}


.subheader {
  min-height: 76px;
  width: 100%;
  background: var(--ion-color-light);
  padding: 16px;

  ion-avatar {
    height: 40px;
    width: 40px;
    margin: 0 12px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }

  ion-item {
    font-size: 14px;
    width: 100%;
    --background: var(--ion-color-light-tint) !important;
    border-radius: 7px;

    ion-icon {
      margin: 0 12px 0 0;
      color: var(--ion-color-medium);
    }

    ion-input {
      --padding-start: 12px;
    }
  }
}

.card-thumbnail {
  margin: 12px 0;
  border-radius: 0;
  box-shadow: none;


  ion-card-content {
    h5::first-letter {
      text-transform: capitalize;
    }

    font-size: 14px;
    text-align: left;
  }

  .image {
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-footer {
    padding: 8px;

    .group-button {
      ion-button {
        ion-icon {
          font-size: 20px;
        }

        ion-text {
          margin-left: 5px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.transparent{
    --background:  transparent !important;
}

.show{
  --background:  var(--ion-color-primary) !important;
}

.read-more {
  font-weight: 600;
  color: var(--ion-color-primary);
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;

  img {
    height: 32px;
    width: 32px;
  }

  p {
    font-size: 14px;
    color: var(--ion-color-medium-shade);
    margin: 0;
  }
}

.menu-header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;

  ion-avatar {
    height: 62px;
    width: 62px;
  }

  h4 {
    margin: 12px 0 0 0;
  }

  span {
    font-size: 12px;
    color: var(--ion-color-medium-shade);
  }
}

.menu-list {
  ion-item {
    font-size: 14px;
  }
}

.menu-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  color: var(--ion-color-medium);
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
}

ion-menu {
  ion-footer {
    ion-toolbar {
      --background: var(--ion-color-light);
    }
  }
}

/**flex style**/
.flex-box {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.fj-start {
  justify-content: start;
}

.fj-end {
  justify-content: end;
}

.fj-center {
  justify-content: center;
}

.fj-space-between {
  justify-content: space-between;
}

.fj-space-around {
  justify-content: space-around;
}

.fj-space-evenly {
  justify-content: space-evenly;
}

.fa-center {
  align-items: center;
}

.fa-start {
  align-items: flex-start;
}

.fa-end {
  align-items: flex-end;
}

/**margin**/
.m-0 {
  margin: 0;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 1rem;
}

.ms-2 {
  margin-left: 2rem;
}

.ms-3 {
  margin-left: 3rem;
}

.ms-4 {
  margin-left: 4rem;
}

/**typography**/

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.right-text {
  text-align: right !important;
}

.hr-accent {
  height: 5px;
  width: 100px;
  background-color: #FFF;
  color: #FFF;
  border-width: 0;
  opacity: 0.9 !important;
  margin: 0px;
}

.subheader-new {
  min-height: 30%;
  width: 100%;
  // background: var(--ion-color-danger);
  background-image: url("assets/images/bg/bg-red.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;

  .title-header {
    font-size: 52px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .title-page-header {
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 1.2;
  }

  .subtitle-header {
    font-size: 22px;
    color: #FFFFFF;
    line-height: 1.2;
    margin-top: 1vw;
  }

  ion-toolbar {
    --background: transparent;
  }

}

.upload-files {
  width: 100%;
  min-height: 125px;
  overflow: hidden;
  margin: 8px 0 0;
  border: dashed .2em #D9D9D9;
  border-radius: 8px;

  .photo-no-valid {
    padding: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ion-icon {
      color: #D9D9D9;
      font-size: 64px;
      margin-right: 0;
    }

    //   ion-text, p {
    //     color: var(--ion-color-text-shade);
    //     font-size: calc(var(--ion-default-size) - 2px);
    //   }
  }

  .photo-valid {
    // border:dashed .2em #D9D9D9;
    // border-radius: inherit;
    width: 100%;
    padding: 16px;
  }
}

.photo-area {
  width: 100%;
  height: 250px;
  border: solid .1em #D9D9D9;
  border-radius: 8px;
  position: relative;
}

.cancel-badge {
  padding: 3px;
  width: 38px;
  height: 38px;
  background-color: #ff4e4e;
  // background-color: red;
  color: #FFF;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
}

.img-photo {
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.img-preview {
  object-fit: cover;
  border-radius: 10px;
}

.btn-footer-next {
  background-color: #F91E1E !important;
}

.text-photo-area {
  color: #807979;
  font-size: 22px;
  text-align: center;
}


/* Loading */

.spinner-wrapper {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top:8%;
  text-align: center;

  .spinner-text {
    color: var(--ion-color-paragraph);
    font-size: calc(var(--ion-default-size) - 2px);
    margin:8px 0 0;
  }

}

.loading-wrapper{
  padding: 5px !important;
}
.sidumas-loading.lds-ring {
  display: inline-block;
  position: relative;
  width: 94px;
  height: 94px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
}
.sidumas-loading.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 81px;
  height: 81px;
  margin: 6px;
  border: 6px solid #f00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f00 transparent transparent transparent;
}
.sidumas-loading.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.sidumas-loading.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.sidumas-loading.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.sidumas-loading.lds-ring img{
  width: 94px;
  padding: 22px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.img-not-found {
  width: 20%;
}

.img-under-construction {
  width: 30%;
}

.text-not-found {
  color: #807979;
  font-weight: lighter;
  text-align: center;
}

.text-name-chat {
  font-weight: bold;
  font-size: 16px;
}

//   splash screen
.img-splash {
  width: 30%;
}

.text-splash {
  font-size: 18px;
  --color: var(--ion-text-color);
}

.top-splash {
  margin-top: 15vh;
}

.bg-splash {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("assets/images/bg/bg-login-accent.png");
  background-repeat: no-repeat;
  background-size: cover;
}

// skeleton
.skeleton-banner {
  width: 100%;
  height: 48vh;
}

.skeleton-image {
  width: 100%;
  height: 38vh;
}

.skeleton-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.skeleton-text {
  width: 100%;
  height: 40px;
}

.skeleton-textarea {
  width: 100%;
  height: 80px;
}

.skeleton-photoarea {
  width: 100%;
  height: 150px;

}

.skeleton-checkbox {
  width: 30px;
  height: 30px;
}

.transparent-modal {
  .modal-wrapper {
    --background: rgba(44, 39, 45, 0.2) !important;
  }
}

.icon-alert {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.text-alert {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 18px;
}
// .ios ion-toolbar{
//   padding-top: 40px !important;
// }

.text-desc {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
}

.text-desc-li {
  font-size: 18px;
  line-height: 1.5;
}

.img-icon{
  width: 25%;
  height: 100%;
  object-fit: contain;
}

.errors-text-display{
  text-align: left !important;
}

.hide-desktop{
  display: none;
}

.hide-mobile{
  display: flex;
}

.sheet-modal {
  --height: auto;
  --max-height: 100%;
}

.navs-menu-text{
  font-size: 16px;
  color: #FFF;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */

  @media only screen and (min-height: 800px) {
    // ion-content #main {
    //   margin-top: 40px;
    // }

    .ios-display {
      padding-top: 40px !important;
    }

    .top-ios{
      margin-top: 20px !important;
    }
  }

  @media only screen and (max-height: 799px) {
    // ion-content #main {
    //   margin-top: 10px;
    // }

    .ios-display {
      padding-top: 10px !important;
    }

    .top-ios{
      margin-top: 10px !important;
    }

  }


}

.ontop-display{
  .alert-wrapper{
    z-index: 9999 !important;
    // position: absolute !important;
    // bottom: 0 !important;
  } 
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .subheader-new {
    width: 100%;
    // background: var(--ion-color-danger);
    background-image: url("assets/images/bg/bg-red.png");
    background-repeat: no-repeat;
    background-size: cover;

    .title-header {
      font-size: 38px;
      font-weight: bold;
      color: #FFFFFF;
    }

    .title-page-header {
      font-size: 22px;
      font-weight: bold;
      color: #FFFFFF;
    }

    .subtitle-header {
      font-size: 14px;
      color: #FFFFFF;
      line-height: 1.2;
      margin-top: 3vw;
    }

    ion-toolbar {
      --background: transparent;
    }

  }

  //   spalash screen
  .img-splash {
    width: 75%;
  }

  .text-splash {
    font-size: 18px;
    --color: var(--ion-text-color);
  }

  .top-splash {
    margin-top: 15vh;
  }

  .bg-splash {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("assets/images/bg/bg-login-mobile-accent.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .img-not-found {
    width: 40%;
  }

  .img-under-construction {
    width: 60%;
  }

  .text-not-found {
    color: #807979;
    font-weight: lighter;
    text-align: center;
  }

  .text-name-chat {
    font-weight: bold;
    font-size: 14px;
  }

  // skeleton
  .skeleton-banner {
    width: 100%;
    height: 25vh;
  }

  .skeleton-image {
    width: 100%;
    height: 28vh;
  }

  .skeleton-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .skeleton-text {
    width: 100%;
    height: 40px;
  }

  .skeleton-textarea {
    width: 100%;
    height: 80px;
  }

  .skeleton-photoarea {
    width: 100%;
    height: 150px;

  }

  .skeleton-checkbox {
    width: 30px;
    height: 30px;
  }

  .text-desc {
    font-size: 16px;
    line-height: 1.5;
  }

  .text-desc-li {
    font-size: 16px;
    line-height: 1.5;
  }

  .hide-desktop{
      display: flex;
  }
  
  .hide-mobile{
      display: none;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .hide-desktop{
    display: flex;
  }
  
  .hide-mobile{
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .hide-desktop{
    display: none;
  }
  
  .hide-mobile{
    display: flex;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
